import { AxiosResponse } from 'axios'
import qs from 'qs';

// Utils
import httpClient from '../../utils/httpClient'

const GENI_API = process.env.REACT_APP_GENI_API
const KULONUWUN_CLIENT_SECRET = process.env.REACT_APP_KULONUWUN_CLIENT_SECRET
const KULONUWUN_CLIENT_ID = process.env.REACT_APP_KULONUWUN_CLIENT_ID

export const postLogin = async (
  username: string,
  password: string
): Promise<AxiosResponse<any>> => {
  try {
    const data = qs.stringify({
      "grant_type": 'password',
      "client_id": KULONUWUN_CLIENT_ID,
      "client_secret": KULONUWUN_CLIENT_SECRET,
      username: username,
      password: password,
    });
    const response = await httpClient({
      baseURL: `${GENI_API}/kulonuwun`,
      url: '/oauth/token',
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
      data: data
    })

    return response
  } catch (error) {
    throw error
  }
}
