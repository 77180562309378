import { useEffect } from "react"
import type { FC } from "react"
import { Link as RouterLink, useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Typography,
} from "@material-ui/core"
import {
  Smartphone,
  Dashboard,
  BurstMode,
  VideoLibrary,
  ViewDay,
  TableView,
  OndemandVideo,
} from "@material-ui/icons"
import useAuth from "../../hooks/useAuth"
import Logo from "../Logo"
import NavSection from "../NavSection"
import Scrollbar from "../Scrollbar"

interface DashboardSidebarProps {
  onMobileClose: () => void
  openMobile: boolean
}

const sections = [
  {
    title: "Template v2",
    items: [
      {
        title: "Template v2",
        path: "/dashboard/v2/template",
        icon: <ViewDay fontSize="small" />,
      },
      {
        title: "Template Group",
        path: "/dashboard/v2/template-group",
        icon: <TableView fontSize="small" />,
      },
      {
        title: "Video v2",
        path: "/dashboard/v2/video",
        icon: <OndemandVideo fontSize="small" />,
      },
    ],
  },
  {
    title: "Pages",
    items: [
      {
        title: "Template",
        path: "/dashboard/template",
        icon: <Dashboard fontSize="small" />,
      },
      {
        title: "Onboarding",
        path: "/dashboard/onboarding",
        icon: <Smartphone fontSize="small" />,
      },
      {
        title: "Variant",
        path: "/dashboard/variant",
        icon: <BurstMode fontSize="small" />,
      },
      {
        title: "Video",
        path: "/dashboard/video",
        icon: <VideoLibrary fontSize="small" />,
      },
    ],
  },
]

const DashboardSidebar: FC<DashboardSidebarProps> = props => {
  const { onMobileClose, openMobile } = props
  const location = useLocation()
  const { user } = useAuth()

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              p: 2,
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 40,
                  width: 40,
                }}
              />
            </RouterLink>
          </Box>
        </Hidden>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: "center",
              backgroundColor: "background.default",
              borderRadius: 1,
              display: "flex",
              overflow: "hidden",
              p: 2,
            }}
          >
            <RouterLink to="/dashboard/account">
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: "pointer",
                  height: 48,
                  width: 48,
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {user.name}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {user.plan}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map(section => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
      </Scrollbar>
    </Box>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              width: 280,
            },
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              height: "calc(100% - 64px) !important",
              top: "64px !Important",
              width: 280,
            },
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
}

export default DashboardSidebar
