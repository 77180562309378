import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Toolbar, Hidden, IconButton,
} from '@material-ui/core';
import {experimentalStyled} from "@material-ui/core/styles";
import MenuIcon from "../../icons/Menu";

const DashboardNavbarRoot = experimentalStyled(AppBar)(
  ({ theme }) => (
    {
      ...(
        theme.palette.mode === 'light' && {
          backgroundColor: theme.palette.primary.main,
          boxShadow: 'none',
          color: theme.palette.primary.contrastText
        }
      ),
      ...(
        theme.palette.mode === 'dark' && {
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.divider}`,
          boxShadow: 'none'
        }
      ),
      zIndex: theme.zIndex.drawer + 100
    }
  )
);


const AuthBanner: FC = () => (
  <DashboardNavbarRoot>
    <Toolbar sx={{ minHeight: 64 }}>
      <Hidden lgUp>
        <IconButton
          color="inherit"
        >
          <MenuIcon fontSize="small" />
        </IconButton>
      </Hidden>
      <Hidden lgDown>
        <RouterLink to="/">
          <img src="/logo.svg" alt="Teras" />
        </RouterLink>
      </Hidden>
    </Toolbar>
  </DashboardNavbarRoot>
);

export default AuthBanner;
