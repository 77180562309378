import { AxiosResponse } from 'axios';

// Utils
import httpClient from '../../utils/httpClient';
import { getGeniHeader } from '../../utils/geniApiHelper';

const TERAS_API = process.env.REACT_APP_TERAS_API;
const GENI_SECRET_KEY = process.env.REACT_APP_GENI_SECRET_KEY;

type Data = {
  name: string;
  campaign_id: number;
  cover_image: string;
  summary_image: string;
  summary_text: string;
  order: number;
};

export const putOnboardingCategory = async (
  id: number,
  data: Data
): Promise<AxiosResponse<any>> => {
  try {
    const response = await httpClient({
      baseURL: TERAS_API,
      url: `/admin/category/${id}`,
      method: 'PUT',
      headers: {
        ...getGeniHeader({
          geniSecretKey: GENI_SECRET_KEY
        })
      },
      data: data
    });

    return response;
  } catch (error) {
    throw error;
  }
};
