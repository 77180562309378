import { AxiosResponse } from 'axios'

// Utils
import httpClient from '../../utils/httpClient'
import { getGeniHeader } from '../../utils/geniApiHelper'

const TERAS_API = process.env.REACT_APP_TERAS_API
const GENI_SECRET_KEY = process.env.REACT_APP_GENI_SECRET_KEY

export const getContentTypeData = async ({ contentType, templateId }): Promise<AxiosResponse<any>> => {
  try {
    const response = await httpClient({
      baseURL: TERAS_API,
      url: `/admin/content_type/${contentType}/${templateId}`,
      method: 'GET',
      headers: {
        ...getGeniHeader({
          geniSecretKey: GENI_SECRET_KEY,
          clientName: 'ios',
          platformName: 'pwa',
          apiVersion: '3.0.0',
          clientVersion: '3.0.0',
        })
      }
    })

    return response
  } catch (error) {
    throw error
  }
}
