import { useEffect } from "react"
import { Helmet } from "react-helmet-async"
import {
  Box,
  Card,
  CardContent,
  Container,
  Typography,
} from "@material-ui/core"
import AuthBanner from "../../components/authentication/AuthBanner"
import {
  LoginAmplify,
  LoginAuth0,
  LoginFirebase,
  LoginJWT,
} from "../../components/authentication/login"
import useAuth from "../../hooks/useAuth"
import gtm from "../../lib/gtm"

const Login = () => {
  const { platform } = useAuth() as any

  useEffect(() => {
    gtm.push({ event: "page_view" })
  }, [])

  return (
    <>
      <Helmet>
        <title>Login | Teras</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <AuthBanner />
        <Container maxWidth="sm" sx={{ py: "80px" }}>
          <Card style={{ transform: "translateY(50%)" }}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 3,
                }}
              >
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    Teras Dashboard
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    Sign in to your account
                  </Typography>
                </div>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                }}
              >
                {platform === "Amplify" && <LoginAmplify />}
                {platform === "Auth0" && <LoginAuth0 />}
                {platform === "Firebase" && <LoginFirebase />}
                {platform === "JWT" && <LoginJWT />}
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  )
}

export default Login
