import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import hmacSHA256 from 'crypto-js/hmac-sha256'
import Hex from 'crypto-js/enc-hex'

interface IGeniHeaderParams {
  clientName?: string,
  platformName?: string,
  clientVersion?: string,
  apiVersion?: string,
  geniSecretKey: string,
}

interface IGeniHeadersReturn {
  'X-Ktbs-Request-ID': string,
  'X-Ktbs-Api-Version': string,
  'X-Ktbs-Client-Version': string,
  'X-Ktbs-Platform-Name': string,
  'X-Ktbs-Client-Name': string,
  'X-Ktbs-Signature': string,
  'X-Ktbs-Time': number
}

export const getGeniHeader = ({
  clientName = 'kanvas',
  platformName = 'pwa',
  apiVersion = '1.0.0',
  clientVersion = '1.0.0',
  geniSecretKey,
}: IGeniHeaderParams): IGeniHeadersReturn => {
  const uuid = uuidv4()
  const dateUnix = dayjs().unix()
  const ktbsClientName = clientName
  const ktbsPlatformName = platformName
  const ktbsSignature = Hex.stringify(
    hmacSHA256(`${ktbsClientName}${dateUnix}`, geniSecretKey)
  )

  const headers = {
    'X-Ktbs-Request-ID': uuid,
    'X-Ktbs-Api-Version': apiVersion,
    'X-Ktbs-Client-Version': clientVersion,
    'X-Ktbs-Platform-Name': ktbsPlatformName,
    'X-Ktbs-Client-Name': ktbsClientName,
    'X-Ktbs-Signature': ktbsSignature,
    'X-Ktbs-Time': dateUnix,
  }

  return headers
}
