import "react-perfect-scrollbar/dist/css/styles.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "react-quill/dist/quill.snow.css"
import "nprogress/nprogress.css"
import "./index.css"
import "./__mocks__"
import { StrictMode } from "react"
import ReactDOM from "react-dom"
import { HelmetProvider } from "react-helmet-async"
import { BrowserRouter } from "react-router-dom"
import { Provider as ReduxProvider } from "react-redux"
import LocalizationProvider from "@material-ui/lab/LocalizationProvider"
import AdapterDateFns from "@material-ui/lab/AdapterDateFns"
import StyledEngineProvider from "@material-ui/core/StyledEngineProvider"
import App from "./App"
import { AuthProvider } from "./contexts/JWTContext"
import { SettingsProvider } from "./contexts/SettingsContext"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import store from "./store"

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <BrowserRouter>
                <AuthProvider>
                  <App />
                </AuthProvider>
              </BrowserRouter>
            </SettingsProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
      </ReduxProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById("root")
)

// If you want to enable client cache, register instead.
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
