import axios from "axios"

const GUDANG_API = process.env.REACT_APP_GUDANG_API
const GUDANG_USERNAME = process.env.REACT_APP_GUDANG_BASIC_AUTH_USERNAME
const GUDANG_PASSWORD = process.env.REACT_APP_GUDANG_BASIC_AUTH_PASSWORD

export const uploadImage = async (imageData: any): Promise<any> => {
  try {
    const response = axios({
      baseURL: GUDANG_API,
      url: "/upload",
      method: "POST",
      auth: {
        username: GUDANG_USERNAME,
        password: GUDANG_PASSWORD,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: imageData,
    })
    return response
  } catch (error) {
    console.log(error)
  }
}
