import { AxiosResponse } from "axios"

// Utils
import httpClient from "../../utils/httpClient"
import { getGeniHeader } from "../../utils/geniApiHelper"

const TERAS_API = process.env.REACT_APP_TERAS_API
const GENI_SECRET_KEY = process.env.REACT_APP_GENI_SECRET_KEY

type Data = {
  video_id?: number
  video_category_id: number
  campaign_id: number
  campaigner_name: string
  thumbnail: string
  view_count: number
  duration: string
  is_deleted: boolean
  youtube_video_id: string
  title: string
}

export const putVideo = async (data: Data): Promise<AxiosResponse<any>> => {
  try {
    const response = await httpClient({
      baseURL: TERAS_API,
      url: `/admin/video`,
      method: "PUT",
      headers: {
        ...getGeniHeader({
          geniSecretKey: GENI_SECRET_KEY,
        }),
      },
      data: data,
    })

    return response
  } catch (error) {
    throw error
  }
}
