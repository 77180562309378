import { mock } from '../lib/axios'

mock.onGet('/api/campaigns').reply(200, {
  campaigns: [
    {
      id: 34,
      rule: {
        id: 78,
        name: 'nemo',
        is_active: false,
        platforms: '',
        created_at: 0,
        created_by: '',
        updated_at: 0,
        updated_by: '',
        max_double_up: 972288405,
        min_threshold: 0,
        payment_method_ids: ''
      },
      brand: {
        id: 91,
        name: 'ipsa',
        status: 'quis',
        user_id: 1888,
        created_at: '1992-05-05T17:54:48+07:00',
        updated_at: '1998-10-05T05:39:39+07:00'
      },
      title: 'Consequuntur incidunt a numquam consequatur quibusdam dicta.',
      end_date: '2020-11-24T11:44:47+07:00',
      is_active: true,
      created_at: '2020-11-24T11:44:47+07:00',
      created_by: '',
      start_date: 1982,
      updated_at: '1986-05-05T02:00:47+07:00',
      updated_by: '',
      campaign_id: 818539256
    },
    {
      id: 32,
      rule: {
        id: 78,
        name: 'Name Rule',
        is_active: false,
        platforms: '',
        created_at: 0,
        created_by: '',
        updated_at: 0,
        updated_by: '',
        max_double_up: 972288405,
        min_threshold: 0,
        payment_method_ids: ''
      },
      brand: {
        id: 91,
        name: 'Judul Brand',
        status: 'quis',
        user_id: 1888,
        created_at: '1992-05-05T17:54:48+07:00',
        updated_at: '1998-10-05T05:39:39+07:00'
      },
      title: 'Judul Campaign',
      end_date: '2021-05-24T10:20:47+07:00',
      is_active: true,
      created_at: '2020-09-19T11:44:47+07:00',
      created_by: '',
      start_date: 1982,
      updated_at: '1986-05-05T02:00:47+07:00',
      updated_by: '',
      campaign_id: 818539256
    }
  ]
})
