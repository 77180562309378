import { AxiosResponse } from "axios"

// Utils
import httpClient from "../../../utils/httpClient"
import { getGeniHeader } from "../../../utils/geniApiHelper"

const TERAS_API = process.env.REACT_APP_TERAS_API
const GENI_SECRET_KEY = process.env.REACT_APP_GENI_SECRET_KEY

export const upsertVideoV2 = async (data): Promise<AxiosResponse<any>> => {
  try {
    const response = await httpClient({
      baseURL: TERAS_API,
      url: `/v2/admin/video`,
      method: "PUT",
      headers: {
        ...getGeniHeader({
          geniSecretKey: GENI_SECRET_KEY,
        }),
      },
      data: data,
    })

    return response
  } catch (error) {
    throw error
  }
}
