import { AxiosResponse } from "axios"

// Utils
import httpClient from "../../utils/httpClient"
import { getGeniHeader } from "../../utils/geniApiHelper"

const TERAS_API = process.env.REACT_APP_TERAS_API
const GENI_SECRET_KEY = process.env.REACT_APP_GENI_SECRET_KEY

export const deleteVideo = async (
  videoId: string
): Promise<AxiosResponse<any>> => {
  try {
    const response = await httpClient({
      baseURL: TERAS_API,
      url: `/admin/video/${videoId}`,
      method: "DELETE",
      headers: {
        ...getGeniHeader({
          geniSecretKey: GENI_SECRET_KEY,
        }),
      },
    })

    return response
  } catch (error) {
    throw error
  }
}
