import axios from "axios"

const YOUTUBE_API = process.env.REACT_APP_YOUTUBE_API
const YOUTUBE_KEY = process.env.REACT_APP_YOUTUBE_API_KEY

export const getYoutubeVideoDetail = (videoId: string): Promise<any> => {
  try {
    const response = axios({
      method: "GET",
      baseURL: YOUTUBE_API,
      url: "/videos",
      params: {
        part: "snippet,contentDetails,statistics",
        id: videoId,
        key: YOUTUBE_KEY,
      },
    })
    return response
  } catch (error) {
    console.log(error)
  }
}
