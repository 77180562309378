import { createSlice } from "@reduxjs/toolkit"
import { getContentTypeV2, getSectionTypeV2 } from "src/services"

const initialState = {
  content_type: [],
  section_type: [],
}

const slice = createSlice({
  name: "templateTypes",
  initialState,
  reducers: {
    getContentType(state, payload) {
      state.content_type = payload.payload
    },
    getSectionType(state, payload) {
      state.section_type = payload.payload
    },
  },
})

export const { reducer } = slice

export const getContentType = () => async dispatch => {
  const contentTypeArr = localStorage.getItem("contentTypeOptions")
  const parsedContentTypeArr = JSON.parse(contentTypeArr)
  if (parsedContentTypeArr && parsedContentTypeArr.length > 0) {
    dispatch(slice.actions.getContentType(parsedContentTypeArr))
  } else {
    const response = await getContentTypeV2()
    const { data } = response?.data

    let tempArr = []
    for (let i = 0; i < data.length; i++) {
      tempArr.push(data[i].content_type)
    }
    localStorage.setItem("contentTypeOptions", JSON.stringify(tempArr))

    dispatch(slice.actions.getContentType(tempArr))
  }
}

export const getSectionType = () => async dispatch => {
  const sectionTypeArr = localStorage.getItem("sectionTypeOptions")
  const parsedSectionTypeArr = JSON.parse(sectionTypeArr)
  if (parsedSectionTypeArr && parsedSectionTypeArr.length > 0) {
    dispatch(slice.actions.getSectionType(parsedSectionTypeArr))
  } else {
    const response = await getSectionTypeV2()
    const { data } = response?.data

    let tempArr = []
    for (let i = 0; i < data.length; i++) {
      tempArr.push(data[i].section_type)
    }
    localStorage.setItem("sectionTypeOptions", JSON.stringify(tempArr))

    dispatch(slice.actions.getSectionType(tempArr))
  }
}

export default slice
