import type { FC } from 'react';
import {
  Box,
  Button,
} from '@material-ui/core';
import useAuth from '../../../hooks/useAuth';

const LoginFirebase: FC = (props) => {
  const { signInWithGoogle } = useAuth() as any;

  const handleGoogleClick = async (): Promise<void> => {
    try {
      await signInWithGoogle();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div {...props}>
      <Button
        fullWidth
        onClick={handleGoogleClick}
        size="large"
        sx={{
          backgroundColor: 'primary.main',
          mb: 1,
          color: 'common.white',
          '&:hover': {
            backgroundColor: 'primary.main',
            color: 'common.white'
          }
        }}
        variant="contained"
      >
        <Box
          alt="Google"
          component="img"
          src="/static/icons/google-alt.svg"
          sx={{ mr: 1 }}
        />
        Sign in with Google
      </Button>
    </div>
  );
};

export default LoginFirebase;
