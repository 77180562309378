import { Suspense, lazy } from "react"
import { PartialRouteObject } from "react-router"
import { Navigate } from "react-router-dom"
import AuthGuard from "./components/AuthGuard"
import BrowseLayout from "./components/BrowseLayout"
import DashboardLayout from "./components/dashboard/DashboardLayout"
import GuestGuard from "./components/GuestGuard"
import LoadingScreen from "./components/LoadingScreen"
import MainLayout from "./components/MainLayout"

const Loadable = Component => props =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  )

// Authentication pages
const Login = Loadable(lazy(() => import("./pages/authentication/Login")))
const PasswordRecovery = Loadable(
  lazy(() => import("./pages/authentication/PasswordRecovery"))
)
const PasswordReset = Loadable(
  lazy(() => import("./pages/authentication/PasswordReset"))
)
const Register = Loadable(lazy(() => import("./pages/authentication/Register")))
const VerifyCode = Loadable(
  lazy(() => import("./pages/authentication/VerifyCode"))
)

// New Templates
const TemplateV2List = Loadable(
  lazy(() => import("./pages/dashboard/v2/template/TemplateV2List"))
)

const TemplateV2Create = Loadable(
  lazy(() => import("./pages/dashboard/v2/template/TemplateV2Create"))
)
const TemplateV2Edit = Loadable(
  lazy(() => import("./pages/dashboard/v2/template/TemplateV2Edit"))
)

// TemplateGroup
const TemplateGroupList = Loadable(
  lazy(() => import("./pages/dashboard/v2/templateGroup/templateGroupList"))
)

const TemplateGroupCreate = Loadable(
  lazy(() => import("./pages/dashboard/v2/templateGroup/templateGroupCreate"))
)
const TemplateGroupEdit = Loadable(
  lazy(() => import("./pages/dashboard/v2/templateGroup/templateGroupEdit"))
)

// Video V2
const VideoV2List = Loadable(
  lazy(() => import("./pages/dashboard/v2/video/VideoV2List"))
)

const VideoV2Create = Loadable(
  lazy(() => import("./pages/dashboard/v2/video/VideoV2Create"))
)
const VideoV2Edit = Loadable(
  lazy(() => import("./pages/dashboard/v2/video/VideoV2Edit"))
)

// Templates
const TemplateList = Loadable(
  lazy(() => import("./pages/dashboard/TemplateList"))
)

const TemplateCreate = Loadable(
  lazy(() => import("./pages/dashboard/TemplateCreate"))
)
const TemplateEdit = Loadable(
  lazy(() => import("./pages/dashboard/TemplateEdit"))
)

// Onboarding
const OnboardingList = Loadable(
  lazy(() => import("./pages/dashboard/OnboardingList"))
)

const OnboardingEdit = Loadable(
  lazy(() => import("./pages/dashboard/OnboardingEdit"))
)

// Variant
const VariantList = Loadable(
  lazy(() => import("./pages/dashboard/VariantList"))
)

const VariantCreate = Loadable(
  lazy(() => import("./pages/dashboard/VariantCreate"))
)
const VariantEdit = Loadable(
  lazy(() => import("./pages/dashboard/VariantEdit"))
)

// Video
const VideoList = Loadable(lazy(() => import("./pages/dashboard/VideoList")))

const VideoCreate = Loadable(
  lazy(() => import("./pages/dashboard/VideoCreate"))
)
const VideoEdit = Loadable(lazy(() => import("./pages/dashboard/VideoEdit")))

// Error pages
const AuthorizationRequired = Loadable(
  lazy(() => import("./pages/AuthorizationRequired"))
)
const NotFound = Loadable(lazy(() => import("./pages/NotFound")))
const ServerError = Loadable(lazy(() => import("./pages/ServerError")))

const routes: PartialRouteObject[] = [
  {
    path: "authentication",
    children: [
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: "login-unguarded",
        element: <Login />,
      },
      {
        path: "password-recovery",
        element: <PasswordRecovery />,
      },
      {
        path: "password-reset",
        element: <PasswordReset />,
      },
      {
        path: "register",
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
      {
        path: "register-unguarded",
        element: <Register />,
      },
      {
        path: "verify-code",
        element: <VerifyCode />,
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Navigate to="/dashboard/template" replace />,
      },
      {
        path: "/v2/template",
        element: <TemplateV2List />,
      },
      {
        path: "/v2/template/create",
        element: <TemplateV2Create />,
      },
      {
        path: "/v2/template/edit/:templateId",
        element: <TemplateV2Edit />,
      },
      {
        path: "/v2/template-group",
        element: <TemplateGroupList />,
      },
      {
        path: "/v2/template-group/create",
        element: <TemplateGroupCreate />,
      },
      {
        path: "/v2/template-group/edit/:templateGroupId",
        element: <TemplateGroupEdit />,
      },
      {
        path: "/v2/video",
        element: <VideoV2List />,
      },
      {
        path: "/v2/video/create",
        element: <VideoV2Create />,
      },
      {
        path: "/v2/video/edit/:videoId",
        element: <VideoV2Edit />,
      },
      {
        path: "/template",
        element: <TemplateList />,
      },
      {
        path: "/template/create",
        element: <TemplateCreate />,
      },
      {
        path: "/templates/edit/:templateId",
        element: <TemplateEdit />,
      },
      {
        path: "/onboarding",
        element: <OnboardingList />,
      },
      {
        path: "/onboarding/edit/:templateId",
        element: <OnboardingEdit />,
      },
      {
        path: "/variant",
        element: <VariantList />,
      },
      {
        path: "/variant/create",
        element: <VariantCreate />,
      },
      {
        path: "/variant/edit/:variantName",
        element: <VariantEdit />,
      },
      {
        path: "/video",
        element: <VideoList />,
      },
      {
        path: "/video/create",
        element: <VideoCreate />,
      },
      {
        path: "/video/edit/:videoId",
        element: <VideoEdit />,
      },
    ],
  },
  {
    path: "Browse",
    element: (
      <AuthGuard>
        <BrowseLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Navigate to="/browse/template" replace />,
      },
    ],
  },
  {
    path: "*",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/dashboard" replace />,
      },
      {
        path: "401",
        element: <AuthorizationRequired />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <ServerError />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]

export default routes
