import { AxiosResponse } from "axios"

// Utils
import httpClient from "../../../utils/httpClient"
import { getGeniHeader } from "../../../utils/geniApiHelper"

const TERAS_API = process.env.REACT_APP_TERAS_API
const GENI_SECRET_KEY = process.env.REACT_APP_GENI_SECRET_KEY

export const createTemplateGroup = async (
  data
): Promise<AxiosResponse<any>> => {
  try {
    const response = await httpClient({
      baseURL: TERAS_API,
      url: `/v2/admin/section/group`,
      method: "POST",
      headers: {
        ...getGeniHeader({
          geniSecretKey: GENI_SECRET_KEY,
          clientName: "ios",
          platformName: "pwa",
          apiVersion: "3.0.0",
          clientVersion: "3.0.0",
        }),
      },
      data,
    })

    return response
  } catch (error) {
    throw error
  }
}
