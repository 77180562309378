import axios from 'axios'

const httpClient = axios.create()

httpClient.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const accessToken = window.localStorage.getItem('accessToken')
    const basicAuth = config.url.includes('/internal/users/wallet/balance')

    if (accessToken && !basicAuth) {
      config.headers.authorization = 'Bearer ' + accessToken
    }

    return {
      ...config
    }
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
httpClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  async function (error) {
    return Promise.reject(error)
  }
)

export default httpClient
