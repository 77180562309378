import { useRef, useState } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  Popover,
  Typography
} from '@material-ui/core';
import useAuth from '../../hooks/useAuth';

const AccountPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        <Avatar
          src={user.avatar}
          sx={{
            height: 32,
            width: 32
          }}
        />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        getContentAnchorEl={null}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            {user.name}
          </Typography>
          <Typography
            color="textSecondary"
            variant="subtitle2"
          >
            Administrator
          </Typography>
        </Box>
        <Divider />
        {/*<Box sx={{ mt: 2 }}>*/}
        {/*  <MenuItem*/}
        {/*    component={RouterLink}*/}
        {/*    to="/dashboard/social/profile"*/}
        {/*  >*/}
        {/*    <ListItemIcon>*/}
        {/*      <UserIcon fontSize="small" />*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText*/}
        {/*      primary={(*/}
        {/*        <Typography*/}
        {/*          color="textPrimary"*/}
        {/*          variant="subtitle2"*/}
        {/*        >*/}
        {/*          Profile*/}
        {/*        </Typography>*/}
        {/*      )}*/}
        {/*    />*/}
        {/*  </MenuItem>*/}
        {/*  <MenuItem*/}
        {/*    component={RouterLink}*/}
        {/*    to="/dashboard/account"*/}
        {/*  >*/}
        {/*    <ListItemIcon>*/}
        {/*      <CogIcon fontSize="small" />*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText*/}
        {/*      primary={(*/}
        {/*        <Typography*/}
        {/*          color="textPrimary"*/}
        {/*          variant="subtitle2"*/}
        {/*        >*/}
        {/*          Settings*/}
        {/*        </Typography>*/}
        {/*      )}*/}
        {/*    />*/}
        {/*  </MenuItem>*/}
        {/*</Box>*/}
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            Logout
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
