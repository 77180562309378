import { createContext, useEffect, useReducer } from "react"
import type { FC, ReactNode } from "react"
import PropTypes from "prop-types"
import axios from "../lib/axios"
import type { User } from "../types/user"
import { postLogin } from "../services"

interface State {
  isInitialized: boolean
  isAuthenticated: boolean
  user: User | null
}

interface AuthContextValue extends State {
  platform: "JWT"
  login: (email: string, password: string) => Promise<void>
  logout: () => Promise<void>
  register: (email: string, name: string, password: string) => Promise<void>
}

interface AuthProviderProps {
  children: ReactNode
}

type InitializeAction = {
  type: "INITIALIZE"
  payload: {
    isAuthenticated: boolean
    user: User | null
  }
}

type LoginAction = {
  type: "LOGIN"
  payload: {
    user: User
  }
}

type LogoutAction = {
  type: "LOGOUT"
}

type RegisterAction = {
  type: "REGISTER"
  payload: {
    user: User
  }
}

type Action = InitializeAction | LoginAction | LogoutAction | RegisterAction

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
}

const setSession = (
  accessToken: string | null,
  expiredDate: string | null,
  refreshToken: string | null
): void => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken)
    localStorage.setItem("refreshToken", refreshToken)
    localStorage.setItem("expiredDate", expiredDate)

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  } else {
    localStorage.removeItem("accessToken")
    localStorage.removeItem("expiredDate")
    localStorage.removeItem("refreshToken")
    delete axios.defaults.headers.common.Authorization
  }
}

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    }
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload

    return {
      ...state,
      isAuthenticated: true,
      user,
    }
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload

    return {
      ...state,
      isAuthenticated: true,
      user,
    }
  },
}

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: "JWT",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
})

export const AuthProvider: FC<AuthProviderProps> = props => {
  const { children } = props
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      const rollback = () =>
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        })
      try {
        const accessToken = window.localStorage.getItem("accessToken")

        // if (accessToken && verify(accessToken, JWT_SECRET)) {
        if (accessToken) {
          const expiredDate = window.localStorage.getItem("expiredDate")
          const refreshToken = window.localStorage.getItem("refreshToken")

          const expObj = new Date(expiredDate)
          const current = new Date()

          if (expObj > current) {
            setSession(accessToken, expiredDate, refreshToken)

            const user = {
              avatar: "",
              email: "Kitabisa Doers",
              name: "Super",
              id: "",
              plan: "Administrator",
            }

            dispatch({
              type: "INITIALIZE",
              payload: {
                isAuthenticated: true,
                user,
              },
            })
          } else {
            rollback()
          }
        } else {
          rollback()
        }
      } catch (err) {
        console.error(err)
        rollback()
      }
    }

    initialize()
  }, [])

  const login = async (username: string, password: string): Promise<void> => {
    const response = await postLogin(username, password)

    const {
      access_token: accessToken,
      expired_in: expiredIn,
      refresh_token: refreshToken,
    } = response?.data?.data[0]

    const user = {
      avatar: "",
      email: username,
      name: "",
      id: "",
    }

    const current = new Date()
    current.setSeconds(current.getSeconds() + expiredIn)

    setSession(accessToken, current.toString(), refreshToken)
    dispatch({
      type: "LOGIN",
      payload: {
        user,
      },
    })
  }

  const logout = async (): Promise<void> => {
    localStorage.removeItem("contentTypeOptions")
    localStorage.removeItem("sectionTypeOptions")
    setSession(null, null, null)
    dispatch({ type: "LOGOUT" })
  }

  const register = async (
    email: string,
    name: string,
    password: string
  ): Promise<void> => {
    const response = await axios.post<{ accessToken: string; user: User }>(
      "/api/authentication/register",
      {
        email,
        name,
        password,
      }
    )
    const { accessToken, user } = response.data

    window.localStorage.setItem("accessToken", accessToken)
    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    })
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "JWT",
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthContext
