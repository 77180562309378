import { AxiosResponse } from 'axios'


// Utils
import httpClient from '../../utils/httpClient'
import { getGeniHeader } from '../../utils/geniApiHelper'

const TERAS_API = process.env.REACT_APP_TERAS_API
const GENI_SECRET_KEY = process.env.REACT_APP_GENI_SECRET_KEY

type Data = {
  id: number,
  type: string,
  title: string,
  info: string,
  is_load_more: false,
  url_load_more: string,
  url_data: string,
  content_type: string,
  is_active: true,
  order: number,
  platform: Array<string>
}

export const deleteTemplate = async (
  data: Data,
): Promise<AxiosResponse<any>> => {
  try {
    const response = await httpClient({
      baseURL: TERAS_API,
      url: '/admin/template',
      method: 'DELETE',
      headers: {
        ...getGeniHeader({
          geniSecretKey: GENI_SECRET_KEY
        })
      },
      data: { templates_to_delete: [{ ...data, data: null }] }
    })

    return response
  } catch (error) {
    throw error
  }
}
